










import { computed, defineComponent } from '@vue/composition-api';
import { usePatentStore } from './store';
import Descriptions from '@/components/Descriptions.vue';
import { addToCollect, getPatentPdfUrl, removeCollect, subscribePatent } from './api';
import { Message } from 'element-ui';
import { useMainStore } from '@/store';

export default defineComponent({
  components: { Descriptions },
  setup(props, { root }) {
    const { state } = usePatentStore();
    const main = useMainStore();
    if (state.current === null) {
      root.$router.replace({ name: 'Patent' });
    }

    const checkLogin = () => {
      if (main.state.user && main.state.jwt) {
        return true;
      } else {
        main.state.showLogin = true;
        return false;
      }
    };

    return {
      detail: state.current,
      isCollect: computed(() => !!state.current?.collectId),
      buttons: computed(() => [
        {
          icon: state.current?.collectId ? 'el-icon-star-on' : 'el-icon-star-off',
          label: state.current?.collectId ? '取消收藏' : '收藏',
          style: state.current?.collectId ? { color: '#E6A23C' } : {},
          onClick: async () => {
            try {
              if (checkLogin() && state.current) {
                if (state.current?.collectId) {
                  await removeCollect(state.current.collectId);
                  Message.success('取消成功');
                  state.current.collectId = undefined;
                } else {
                  const res = await addToCollect('patent', state.current, state.current?.no);
                  Message.success('收藏成功');
                  state.current.collectId = res.id;
                }
              }
            } catch (err) {
              if (err.statusCode === 400) {
                Message.warning('已收藏');
              }
            }
          },
        },
        {
          icon: 'el-icon-bell',
          label: '加入提醒',
          onClick: async () => {
            try {
              if (checkLogin() && state.current) {
                await subscribePatent(state.current);
                Message.success('添加成功');
              }
            } catch (err) {
              if (err.statusCode === 400) {
                Message.warning('已添加到提醒列表');
              }
            }
          },
        },
        {
          icon: 'el-icon-download',
          label: '下载PDF',
          onClick: async () => {
            if (checkLogin() && state.current?.patentSn) {
              try {
                const res = await getPatentPdfUrl(state.current.patentSn);
                if (res.data.shortPdfUrl) {
                  const a = document.createElement('a');
                  a.setAttribute('href', res.data.shortPdfUrl);
                  a.setAttribute('target', '_blank');
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                } else {
                  throw new Error();
                }
              } catch {
                Message.error('获取下载地址失败，请联系管理员');
              }
            }
          },
        },
      ]),
    };
  },
});
