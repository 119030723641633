


























import { defineComponent } from '@vue/composition-api';
import { usePatentStore } from './store';
import { Patent } from './api';
import Descriptions from '@/components/Descriptions.vue';
import Float from './Float.vue';

export default defineComponent({
  components: { Descriptions, Float },
  setup(props, { root }) {
    const { state } = usePatentStore();
    if (state.current === null) {
      root.$router.replace({ name: 'Patent' });
    }

    return { detail: state.current };
  },
});
